import React from "react";
import { generatePath } from "react-router-dom";

import ListingsItem from "components/frontend/listings/_shared/listingItem";
import Routes from "routes";
import trimText from "utils/trimText";

export default function SaleListItem(props) {
  const { listing, plan, featured, company } = props;

  const usePlan = plan || listing.company.plan;
  const useCompany = company || listing.company;

  let listingMode = "basic";

  if (usePlan && usePlan.rank > 0) {
    listingMode = "subscriber";
  }
  if (featured || listing.isFeatured > 0) {
    // Set display type to Subscriber or Basic/Free
    listingMode = "featured";
  }

  const addr = [];
  if (listing.city) addr.push(listing.city);
  if (listing.city && listing.state) addr.push(",");
  if (listing.state) addr.push(listing.state.value);
  if (listing.zip) addr.push(listing.zip);
  if (listing.country) addr.push(listing.country.title);

  const priceDisplay = () => {
    const { price, salePrice } = listing.saleDetails;

    let salePriceDisplay;
    if (salePrice) {
      switch (salePrice.value) {
        case "price":
          if (price) {  
            salePriceDisplay = new Intl.NumberFormat("en-us", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
               maximumFractionDigits: 0
            }).format(price);
          } else {
            salePriceDisplay = "Unknown";
          }

          break;
        default:
          salePriceDisplay = salePrice.title;
          break;
      }
    } else if (price) {
      salePriceDisplay = new Intl.NumberFormat("en-us", {
        style: "currency",
        currency: "USD",
      }).format(price);
    }

    if (salePriceDisplay) {
      return (
        <div className="meta-row meta-price">
          <span className="meta-label">Price:</span>{" "}
          <span className="meta-value">{salePriceDisplay}</span>
        </div>
      );
    }
    return <></>;
  };

  return (
    <ListingsItem
      className="for-sale-listing"
      listing={listing}
      image={listing.thumbnail}
      title={listing.title}
      description={
        listing.description ? trimText(listing.description, 250) : undefined
      }
      link={generatePath(Routes.forSaleDetail, {
        listing: listing.slug,
      })}
      type={listing.saleDetails.model.type.value}
      mode={listingMode}
      account={listing.account}
      company={useCompany}
    >
      {priceDisplay()}
      {listing.saleDetails.year && (
        <div className="meta-row meta-year">
          <span className="meta-label">Year:</span>{" "}
          <span className="meta-value">{listing.saleDetails.year}</span>
        </div>
      )}

      {listing.saleDetails.totalTime && (
        <div className="meta-row meta-total-time">
          <span className="meta-label">Total Time:</span>{" "}
          <span className="meta-value">
            {new Intl.NumberFormat("en-us").format(
              listing.saleDetails.totalTime
            )}
          </span>
        </div>
      )}
    </ListingsItem>
  );
}
